import { graphql } from "gatsby";
import React from "react";
import Layout from "~components/layouts/Layout";
import LandingpagePersonalHeader from "~components/sections/landingpages/LandingpagePersonalHeader";
import SeoText from "~components/sections/landingpages/SeoText";

export default function Page({ data, location }) {
	const keyword = "React Entwickler";
	const title = "React Entwickler ▷ Freelancer zum Fixpreis";
	const description =
		"Suchst Du einen zuverlässigen React Entwickler Freelancer? Wir helfen Dir gerne bei Deinem Projekt!";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<LandingpagePersonalHeader
				title={title}
				description={description}
				keyword={keyword}
				schemaImage={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
				price="90"
				experienceYears="3"
				skills={["Create React App", "Gatsby", "Next"]}
				siteUrl={data.site.siteMetadata.siteUrl}
				path={location.pathname}
			/>

			<SeoText
				headline="Was ist React.js?"
				intro={[
					"React.js ist eine JavaScript Bibliothek die es ermöglicht interaktive Applikationen für das Web und Mobile Apps zu programmieren. Die Bibliothek spezialisiert sich dabei auf das Frontend und dessen Interaktivität. React.js ist eine Open-Source-Software und wurde von Jordan Walke, einem Facebook Entwickler, erfunden.",
				]}
			/>

			<SeoText
				headline="Kernkonzepte"
				intro={[
					"React.js bringt einige grundlegende Kernkonzepte mit, die es zu verstehen gilt. Als React.js Webdesigner sind wir uns natürlich dieser Konzepte bewusst und möchten sie Dir näher bringen:",
				]}
				text={[
					{
						headline: "Unidirektionaler Datenfluss",
						text: [
							"Um das Arbeiten mit den Daten zu vereinfachen, gibt es bei React nur einen unidirektionalen Datenfluss. Das soll den Aufbau und die Wechselwirkungen zwischen den Komponenten vereinfachen. So werden Eigenschaften und Funktionen von oben nach unten weitergegeben. Die Kind-Elemente können die übergebenen Eigenschaften nicht ändern und nur mit übergebenen Callback-Funktionen die übergeordneten Komponenten beeinflussen.",
							"Dennoch gibt es auch die den eingebauten Context, oder externe Bibliotheken wie Zustand oder Redux, um globale Daten zu speichern und von unteren Komponenten direkt abgerufen und manipuliert werden können. Der React Entwickler entscheidet hier welches Vorgehen sinnvoll für die Applikation ist.",
						],
					},
					{
						headline: "Virtual DOM und DOM-Diffing",
						text: [
							"Normale JavaScript DOM-Manipilationen wirken sich direkt auf das &bdquo;richtige&ldquo; DOM (Document Object Model) aus. React.js unterscheidet sich in der Hinsicht, dass es neben dem normalen DOM einen virtuellen DOM parallel aufbaut. Die virtuelle DOM lebt im Speicher und ist eine abstrakte Kopie des tatsächlichen DOMs, die deutlich kleiner ist und sich auf das Nötigste an Informationen beschränkt. Durch die Verwendung von virtuellem DOM kann React besser herausfinden was geändert wurde und damit Teilbereiche auf der echten DOM anwenden, anstatt das gesamte DOM zu ersetzen",
							"Der Prozess der präzisen Änderungen nennt React.js &bdquo;DOM-Diffing&ldquo;. Es beschreibt das selektive Aktualisieren des DOM auf Basis eines Vergleiches zwischen dem aktuellen DOM und dem geänderten virtuellem DOM.",
						],
					},
					{
						headline: "JavaScript syntax extension (JSX)",
						text: [
							"Man hasst es oder man liebt es. React.js liefert eine Template-Sprache mit die sich an XML anlehnt und wie HTML aussieht. So wird JavaScript und HTML in der Entwicklung von React-Apps mehr verwoben, weil Javascript-Logik, HTML und CSS in eine React-Komponente eingekapselt werden und modular in Applikation eingesetzt werden kann.",
						],
					},
				]}
			/>
			<SeoText
				headline="Vorteile React"
				intro={["Warum React.js für Dich interessant sein kann:"]}
				text={[
					{
						headline: "Dynamische Applikationen",
						text: ["React.js macht es sehr einfach dynamische Applikationen mit wenig Code zu bauen."],
					},
					{
						headline: "Gute Performance",
						text: [
							"Durch das DOM-Difing werden nur Teilbereiche der DOM ausgetauscht, statt alle Komponenten neu zu rendern.",
						],
					},
					{
						headline: "Template-Engine",
						text: [
							"Durch das verkapseln einzelner Teilbereiche und Komponenten, wird direkt eine brauchbare Template-Engine mitgeliefert.",
						],
					},
					{
						headline: "Übersicht",
						text: [
							"Durch den unidirektionalen Datenfluss und den Aufbau in einzelne Komponenten, ist eine bessere Übersicht des Programmcodes gegeben.",
						],
					},
				]}
			/>

			<SeoText
				headline="React.js und SEO"
				intro={[
					"React.js ist aus der SEO-Sicht suboptimal, da JavaScript ausgeführt werden muss, um das Frontend anzuzeigen. Die Inhalte werden also nicht sofort als HTML ausgeliefert, sondern werden erst beim Seitenaufruf erstellt.",
					"Ein Problem für den Google Crawler? Google hat vor einigen Jahren angekündigt hat, dass der Crawler mittlerweile auch JavaScript ausführt. Die Sache hat nur einen Haken: HTML Webseiten werden dennoch besser verstanden als JavaScript-Webseiten.",
				]}
				text={[
					{
						headline: "React.js Applikationen als HTML ausliefern",
						text: [
							"Der Schlüssel React.js SEO-freundlich zu machen ist es, die Website, als HTML-Seite auszuliefern. Dazu können wir entweder einen Static Site Generator oder einen Server-Side Generator benutzen. Beide führen den JavaScript Code aus bevor die Website vom Google Crawler aufgerufen wird. Die Website wird als fertige HTML bereitgestellt. Es gibt mehrere Frameworks, die auf React.js aufbauen, was es sehr einfach macht, serverseitiges Rendering für eine React-Seite einzurichten. Zwei gängige Frameworks sind Gatsby.js und Next.js.",
						],
					},
					{
						headline: "Gatsby.js der Static Site Generator",
						text: [
							"Gatsby.js ist ein React-Framework zum Generieren statischer Seiten, bei dem Sie alle Seiten händisch auf dem Computer, des Programmierers oder in einer Cloud generiert werden und dann als vorgenerierte Seite im Web bereitgestellt wird. Das spart Server kosten, da die Seiten statisch bereitliegen. Gatsby.js ist eine gute Alternative für Seiten, auf denen sich der Inhalt nicht in Echtzeit ändern muss. Beispiele für Websites, die hervorragend mit Gatsby funktionieren, sind Blogs und Unternehmensseiten. Gatsby.js ist weniger optimal für Seiten mit vielen benutzergenerierten Inhalten.",
							<i>
								Update 19.09.2021: Gatsby.js hat angekündigt mit der Version 4 nun auch Server-Side Rendering
								anzubieten.
							</i>,
						],
					},
					{
						headline: "Next.js der Server-Side Generator",
						text: [
							"Next.js verfolgt einen anderen Ansatz als Gatsby.js (auch wenn es wie Gatsby.js die Möglichkeit bietet, statische Seiten zu generieren). Wenn ein Benutzer eine Seite zum ersten Mal besucht, rendert Next.js die Seite serverseitig und liefer diese aus. Der große Vorteil von Next.js im Vergleich zu Gatsby.js besteht darin, dass es generiert wird, wenn der Benutzer die Seite anfordert, was bedeutet, dass der Inhalt immer in Echtzeit aktualisiert wird. Das erfordert allerdings einen Server, der ständig aktiv sein muss. Next.js ist eine gute Wahl für Seiten, die sich ständig ändern.",
						],
					},
				]}
			/>
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "pages/leistungen/react-entwickler/react-entwickler_seo-image.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
